import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Message, MessageSender } from "./types";
import { FiBiVoiceBottomBarProps } from "./FiBiVoiceBottomBar";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../../../store/userData";
import useOpenAiTTS from "../../../../../../hooks/useOpenAiTTS";
import { toast } from "react-toastify";

const useFiBiVoiceBottomBar = ({
  businessProps,
  onAddToChatHistory,
  audioContextRef,
  audioRef,
  allMessages,
  requestFunc,
}: FiBiVoiceBottomBarProps) => {
  const { userData } = useSnapshot(userDataStore);
  const userName = userData?.userInfo?.name.split(" ")[0];
  const [input, setInput] = useState<string>("");
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    isLoading: loadingAudio,
    audioUrl: openAiAudioUrl,
    synthesize: openAiSpeak,
  } = useOpenAiTTS();
  const [audioUrl, setAudioUrl] = useState<string>("");

  useEffect(() => {
    if (loadingAudio) {
      setAudioUrl("");
      return;
    }
    if (openAiAudioUrl) setAudioUrl(openAiAudioUrl);
  }, [openAiAudioUrl, loadingAudio]);

  const recognition = useMemo(() => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      const rec = new (window as any).webkitSpeechRecognition();
      rec.continuous = true;
      rec.interimResults = false;
      rec.lang = "en-US";
      return rec;
    }
    return null;
  }, []);

  useEffect(() => {
    const requestMicrophoneAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log("Microphone access granted.");
      } catch (error) {
        console.error("Microphone access denied:", error);
        alert("Please enable microphone access in your browser settings.");
      }
    };

    const checkMicPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "microphone" as PermissionName, // TypeScript cast for browser compatibility
        });

        return permissionStatus.state; // "granted", "denied", or "prompt"
      } catch (error) {
        console.error("Permissions API not supported:", error);
        return "prompt"; // Fallback for browsers without Permissions API
      }
    };

    requestMicrophoneAccess();
    checkMicPermission();
  }, []);

  // ^ error handling
  useEffect(() => {
    if (recognition) {
      recognition.onerror = (event: any) => {
        console.error("Speech recognition error:", event.error);
      };

      recognition.onend = () => {
        setIsListening(false);
      };

      return () => {
        recognition.onresult = null;
        recognition.onend = null;
        recognition.onerror = null;
      };
    }
  }, [recognition]);

  // Stop playing audio
  const stopPlayingAudio = useCallback(() => {
    if (audioRef.current) {
      // Remove any existing event listeners first
      audioRef.current.onended = null;

      // Check if there's actual playback happening
      if (!audioRef.current.paused) {
        audioRef.current.pause();
      }

      // Reset the time regardless of play state
      audioRef.current.currentTime = 0;

      // Update the speaking state
      setIsSpeaking(false);
    }
  }, [audioRef]); // No need to include audioRef as a dependency since it's a ref

  const handlePlayAudio = useCallback(
    (text: string) => {
      stopPlayingAudio();
      openAiSpeak(text);
    },
    [openAiSpeak, stopPlayingAudio]
  );

  const baseFetch = useCallback(
    async ({ prompt }: { prompt: string }) => {
      setLoading(true);

      try {
        const result = await requestFunc(prompt);

        if (!result) return;

        const aiResponse = {
          sender: MessageSender.bot,
          message: result.data.result,
        };
        onAddToChatHistory(aiResponse);

        return result.data.result;
      } catch (error) {
        const errorMessage = {
          sender: MessageSender.bot,
          message:
            "We're experiencing a temporary hiccup. Feel free to retry your request. If you still encounter problems, our support team at support@seamlessvisa.com is here for you.",
        };
        onAddToChatHistory(errorMessage);
        console.error("Error generating response:", error);
      } finally {
        setLoading(false);
        setInput("");
      }
    },
    [onAddToChatHistory, requestFunc]
  );

  const sendMessage = useCallback(
    async (tempData?: string) => {
      const temp = tempData || input;
      if (temp.trim()) {
        const newMessage: Message = {
          message: temp.trim(),
          sender: MessageSender.user,
        };
        onAddToChatHistory(newMessage);
        setInput("");

        const result = await baseFetch({
          prompt: temp,
        });

        if (result) {
          handlePlayAudio(result);
        }
      }
    },
    [baseFetch, onAddToChatHistory, input, handlePlayAudio]
  );

  // * STOPPING SPEECH RECOGNITION
  const stopListening = useCallback(() => {
    if (recognition) {
      recognition.stop();
      setInput("");
      setIsListening(false);
    }
  }, [recognition]);

  const timerRef = useRef<NodeJS.Timeout | number | null>(null);

  const startListening = useCallback(() => {
    if (recognition) {
      let pauseTimer: NodeJS.Timeout | null = null;
      // let pauseTimer: number | null = null;
      let finalTranscript = "";
      let isSending = false; // Flag to prevent multiple sends

      const resetPauseTimer = () => {
        if (pauseTimer) clearTimeout(pauseTimer);

        pauseTimer = setTimeout(() => {
          stopListening();

          // Only send if we have text and aren't already sending
          if (finalTranscript.trim() !== "" && !isSending) {
            isSending = true;

            // Use the dedicated function to stop audio
            stopPlayingAudio();

            // Small delay before sending to ensure audio processing is complete
            setTimeout(() => {
              sendMessage(finalTranscript);
              finalTranscript = ""; // Reset final transcript after sending
              isSending = false;
            }, 100);
          }
        }, 2500);
      };

      recognition.onresult = (event: any) => {
        let interimTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }

        setInput(finalTranscript + interimTranscript);

        resetPauseTimer();
      };

      recognition.onend = () => {
        setIsListening(false);
        if (timerRef) clearTimeout(timerRef.current as NodeJS.Timeout);
      };

      recognition.start();
      setIsListening(true);
    }
  }, [recognition, stopPlayingAudio, stopListening, sendMessage]);

  // Define the playAudio function using useCallback to maintain referential identity
  const playAudio = useCallback(
    async (audioUrl: string) => {
      const audio = audioRef.current;

      // Stop any current playback
      audio.pause();
      audio.currentTime = 0;

      // Remove any existing event listeners
      audio.onended = null;

      // Set new source
      audio.src = audioUrl;

      // Set up new handlers
      audio.onended = () => {
        setIsSpeaking(false);
        console.log("Audio playback completed");
        startListening();
        setAudioUrl("");
      };

      // Set speaking state before attempting playback
      setIsSpeaking(true);

      if (audioContextRef.current?.state === "suspended") {
        await audioContextRef.current.resume();
      }

      // Play audio
      audio
        .play()
        .then(() => {
          console.log("Audio playback started successfully");
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
          setIsSpeaking(false);
          toast.error(`Audio error: ${error.message}`);
        });
    },
    [audioContextRef, audioRef, startListening]
  ); // Add any dependencies here

  // Effect to play audio when URL changes
  useEffect(() => {
    if (audioUrl.trim() !== "") {
      playAudio(audioUrl);
    }
  }, [audioUrl, playAudio]);

  useEffect(() => {
    if (allMessages.length > 0) return;

    const fetch = async () => {
      const result = await baseFetch({
        prompt: "",
      });

      if (result) {
        handlePlayAudio(result);
      }
    };

    fetch();
    // baseFetch({ id: "", prompt: "" });
  }, [allMessages.length, baseFetch, handlePlayAudio]);

  const onSpeak = () => {
    const lastBot = allMessages
      .slice()
      .reverse()
      .find((msg) => msg.sender === MessageSender.bot);

    if (!lastBot?.message) return;
    setAudioUrl("");
    handlePlayAudio(lastBot.message);
  };

  const startStopMic = () => (isSpeaking ? stopPlayingAudio() : onSpeak());

  return {
    loadingAudio,
    startStopMic,
    isSpeaking,
    isListening,
    businessProps,
    loading,
    userName,
    stopListening,
    startListening,
  };
};

export default useFiBiVoiceBottomBar;
